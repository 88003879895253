"use client";

import { HardDrives, Stack, X } from "@jengaicons/react";
import { Row } from "@primitives/layout";
import { Text } from "@primitives/text";
import { motion } from "framer-motion";

import { Section, SectionBody } from "../sections";

export function JobQueue() {
  return (
    <Section className="">
      <SectionBody className="border-y px-4 py-8 md:p-16">
        {/* <Row className="text-soft mb-3 items-center gap-2 md:justify-center">
          <Stack size={20} />
          <Text color="soft" variant="headingMd" weight="semibold">
            Job Queues & Batch Processing
          </Text>
        </Row> */}
        <Text
          variant="heading3Xl"
          weight="bold"
          className="mx-auto text-left md:w-3/4 md:text-center"
        >
          Everything you need to build an AI product
        </Text>
      </SectionBody>

      <SectionBody className="grid divide-y sm:grid-cols-3 sm:divide-x sm:divide-y-0">
        <div className="">
          <Text
            variant="headingXl"
            weight="semibold"
            className="px-4 py-8 lg:px-16"
          >
            Environments
          </Text>
          <div className="relative flex h-[260px] justify-end overflow-hidden border-y">
            <motion.div
              initial={{
                translateX: 100,
              }}
              style={{
                opacity: 0,
              }}
              whileInView={{
                translateX: 0,
                opacity: 1,
              }}
              transition={{
                duration: 0.5,
              }}
              className="absolute left-0 pl-16 pt-16 md:pl-16"
            >
              <EnvironmentsWindow />
            </motion.div>
          </div>
          <Text variant="bodyXl" color="soft" className="p-8 px-4 lg:px-16">
            Define container images and hardware specs in code, and serve any
            function as HTTPS endpoints.
          </Text>
        </div>
        <div className="">
          <Text
            variant="headingXl"
            weight="semibold"
            className="px-4 py-8 lg:px-16"
          >
            Storage
          </Text>
          <div className="relative flex h-[260px] justify-end overflow-hidden border-y">
            <motion.div
              initial={{
                translateX: 100,
              }}
              style={{
                opacity: 0,
              }}
              whileInView={{
                translateX: 0,
                opacity: 1,
              }}
              transition={{
                duration: 0.5,
              }}
              className="absolute left-0 pl-16 pt-16 md:pl-16"
            >
              <StorageWindow />
            </motion.div>
          </div>
          <Text variant="bodyXl" color="soft" className="p-8 px-4 lg:px-16">
            Easily provision network volumes, key-value stores, and queues using
            powerful cloud primitives that feel like regular Python.
          </Text>
        </div>

        <div className="">
          <Text
            variant="headingXl"
            weight="semibold"
            className="px-4 py-8 lg:px-16"
          >
            Scheduling
          </Text>
          <div className="relative flex h-[260px] justify-end overflow-hidden border-y">
            <motion.div
              initial={{
                translateX: 100,
              }}
              style={{
                opacity: 0,
              }}
              whileInView={{
                translateX: 0,
                opacity: 1,
              }}
              transition={{
                duration: 0.5,
              }}
              className="absolute left-0 pl-16 pt-16 md:pl-16"
            >
              <JobSchedulingWindow />
            </motion.div>
          </div>
          <Text variant="bodyXl" color="soft" className="p-8 px-4 lg:px-16">
            Transform functions into cron jobs with just one line of code.
            Execute compute-intensive tasks without blocking your backend.
          </Text>
        </div>
      </SectionBody>
    </Section>
  );
}

function EnvironmentsWindow() {
  return (
    <div className="origin] relative">
      <div className="mb-[58px] flex h-[60px] w-full flex-row items-center gap-2">
        <div className="border-w-2 bg-default flex h-[60px] w-[191px] flex-row items-center justify-center rounded border-2 border-[#A297FF]">
          <div className="px-2">
            <div className="bg-success h-2 w-2 rounded-full" />
          </div>
          <div className="flex flex-col">
            <p className="text-[14px]">Connected</p>
            <p className="text-soft text-[12px]">30 cores • A100 • 200 GiB</p>
          </div>
        </div>
        <div className="border-w-2 bg-tertiary-hovered flex h-[60px] w-[191px] flex-row items-center rounded border-2 pl-1">
          <div className="px-2">
            <div className="bg-hovered h-2 w-2 rounded-full" />
          </div>
          <div className="flex flex-col">
            <p className="text-[14px]">Offline</p>
            <p className="text-soft text-[12px]">4 cores • T4 • 32Gi</p>
          </div>
        </div>
      </div>
      <div className="relative">
        <div className="absolute bottom-0 left-[72px]">
          <svg
            width="148"
            height="59"
            viewBox="0 0 148 59"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="hidden dark:block"
          >
            <path
              d="M1 59V46.2448C1 39.6173 6.37258 34.2448 13 34.2448H135C141.627 34.2448 147 28.8722 147 22.2448V0"
              stroke="#27272A"
            />
          </svg>
          <svg
            width="148"
            height="59"
            viewBox="0 0 148 59"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="dark:hidden"
          >
            <path
              d="M1 59V46.2448C1 39.6173 6.37258 34.2448 13 34.2448H135C141.627 34.2448 147 28.8722 147 22.2448V0"
              stroke="#E4E4E7"
            />
          </svg>
        </div>

        <div className="absolute bottom-0 left-[72px]">
          <svg
            width="238"
            height="26"
            viewBox="0 0 238 26"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="hidden dark:block"
          >
            <path d="M1 26V13C1 6.37258 6.37258 1 13 1H238" stroke="#27272A" />
          </svg>
          <svg
            width="238"
            height="26"
            viewBox="0 0 238 26"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="dark:hidden"
          >
            <path d="M1 26V13C1 6.37258 6.37258 1 13 1H238" stroke="#E4E4E7" />
          </svg>
        </div>
        <div className="z-1 absolute bottom-0 left-[50px]">
          <svg
            width="24"
            height="58"
            viewBox="0 0 24 58"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="hidden dark:block"
          >
            <path
              d="M23 58V32C23 29.7909 21.2091 28 19 28H5C2.79086 28 1 26.2091 1 24V0"
              stroke="url(#paint0_linear_5935_146585)"
              stroke-width="2"
            />
            <defs>
              <linearGradient
                id="paint0_linear_5935_146585"
                x1="12"
                y1="18"
                x2="12"
                y2="58"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#A297FF" />
                <stop offset="1" stop-color="#27272A" />
              </linearGradient>
            </defs>
          </svg>
          <svg
            width="24"
            height="58"
            viewBox="0 0 24 58"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="dark:hidden"
          >
            <path
              d="M23 58V32C23 29.7909 21.2091 28 19 28H5C2.79086 28 1 26.2091 1 24V0"
              stroke="url(#paint0_linear_5935_147861)"
              stroke-width="2"
            />
            <defs>
              <linearGradient
                id="paint0_linear_5935_147861"
                x1="12"
                y1="18"
                x2="12"
                y2="58"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#7F7AFF" />
                <stop offset="1" stop-color="#E4E4E7" />
              </linearGradient>
            </defs>
          </svg>
        </div>
      </div>
      <div className="h-[234px] w-[334px] rounded-lg border">
        <div className="flex h-[32px] flex-row">
          <div className="flex h-[32px] w-[48px] flex-row items-center justify-center gap-1 border-b border-r">
            <div className="bg-pressed dark:bg-hovered h-2 w-2 rounded-full" />
            <div className="bg-pressed dark:bg-hovered h-2 w-2 rounded-full" />
            <div className="bg-pressed dark:bg-hovered h-2 w-2 rounded-full" />
          </div>
          <div className="flex h-[32px] w-[80px] flex-row items-center justify-center gap-2 border-r">
            <p className="text-soft text-[12px]">app.py</p>{" "}
            <X className="text-icon-soft" size={10} />
          </div>
          <div className="w-[210px] border-b" />
        </div>
        <div className="flex flex-col gap-2 px-3 py-4">
          <div className="flex flex-row items-start gap-2">
            <div className="bg-pressed dark:bg-hovered h-2 w-[66px] rounded-sm" />
            <div className="bg-pressed dark:bg-hovered h-2 w-[66px] rounded-sm" />
          </div>
          <div className="flex flex-row items-start gap-2">
            <div className="bg-pressed dark:bg-hovered h-2 w-[103px] rounded-sm" />
            <div className="bg-pressed dark:bg-hovered h-2 w-[199px] rounded-sm" />
          </div>
        </div>
      </div>
    </div>
  );
}

function JobSchedulingWindow() {
  return (
    <div className="origin] relative">
      <div className="mb-[58px] flex h-[60px] w-full flex-row items-center gap-2">
        <div className="border-w-2 bg-default flex h-[60px] w-[134.56px] flex-row items-center rounded border-2 border-[#A297FF] pl-1">
          <div className="flex flex-col px-2">
            <HardDrives className="text-icon-soft m-0 p-0" size={16} />
          </div>
          <div className="flex flex-col">
            <p className="text-[14px]">Replica #1</p>
            <p className="text-soft text-[12px]">11 tasks</p>
          </div>
        </div>
        <div className="border-w-2 bg-default flex h-[60px] w-[134.56px] flex-row items-center rounded border-2 border-[#A297FF] pl-1">
          <div className="flex flex-col px-2">
            <HardDrives className="text-icon-soft m-0 p-0" size={16} />
          </div>
          <div className="flex flex-col">
            <p className="text-[14px]">Replica #2</p>
            <p className="text-soft text-[12px]">5 tasks</p>
          </div>
        </div>
        <div className="border-w-2 bg-tertiary-hovered flex h-[60px] w-[134.56px] flex-row items-center rounded border-2 pl-1"></div>
      </div>
      <div className="relative">
        <div className="absolute bottom-0 left-[72px]">
          <svg
            width="130"
            height="58"
            viewBox="0 0 130 58"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="dark:hidden"
          >
            <path
              d="M1 58V40C1 33.3726 6.37258 28 13 28H117C123.627 28 129 22.6274 129 16V0"
              stroke="url(#paint0_linear_5916_98613)"
              stroke-width="2"
            />
            <defs>
              <linearGradient
                id="paint0_linear_5916_98613"
                x1="65"
                y1="18"
                x2="65"
                y2="58"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#7F7AFF" />
                <stop offset="1" stop-color="#E4E4E7" />
              </linearGradient>
            </defs>
          </svg>

          <svg
            width="235"
            height="59"
            viewBox="0 0 235 59"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="hidden dark:block"
          >
            <path
              d="M1.00004 59L1.00001 41.2521C1.00001 34.6247 6.37259 29.2521 13 29.2521H267C273.627 29.2521 279 23.8795 279 17.2521V0"
              stroke="#27272A"
            />
          </svg>
        </div>
        <div className="absolute bottom-0 left-[72px]">
          <svg
            width="130"
            height="58"
            viewBox="0 0 130 58"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="hidden dark:block"
          >
            <path
              d="M1 58V40C1 33.3726 6.37258 28 13 28H117C123.627 28 129 22.6274 129 16V0"
              stroke="url(#paint0_linear_5935_146961)"
              stroke-width="2"
            />
            <defs>
              <linearGradient
                id="paint0_linear_5935_146961"
                x1="65"
                y1="18"
                x2="65"
                y2="58"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#A297FF" />
                <stop offset="1" stop-color="#27272A" />
              </linearGradient>
            </defs>
          </svg>

          <svg
            width="130"
            height="58"
            viewBox="0 0 130 58"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="dark:hidden"
          >
            <path
              d="M1 58V40C1 33.3726 6.37258 28 13 28H117C123.627 28 129 22.6274 129 16V0"
              stroke="url(#paint0_linear_5935_147995)"
              stroke-width="2"
            />
            <defs>
              <linearGradient
                id="paint0_linear_5935_147995"
                x1="65"
                y1="18"
                x2="65"
                y2="58"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#7F7AFF" />
                <stop offset="1" stop-color="#E4E4E7" />
              </linearGradient>
            </defs>
          </svg>
        </div>

        <div className="z-1 absolute bottom-0 left-[50px]">
          <svg
            width="24"
            height="58"
            viewBox="0 0 24 58"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="hidden dark:block"
          >
            <path
              d="M23 58V32C23 29.7909 21.2091 28 19 28H5C2.79086 28 1 26.2091 1 24V0"
              stroke="url(#paint0_linear_5935_146585)"
              stroke-width="2"
            />
            <defs>
              <linearGradient
                id="paint0_linear_5935_146585"
                x1="12"
                y1="18"
                x2="12"
                y2="58"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#A297FF" />
                <stop offset="1" stop-color="#27272A" />
              </linearGradient>
            </defs>
          </svg>
          <svg
            width="24"
            height="58"
            viewBox="0 0 24 58"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="dark:hidden"
          >
            <path
              d="M23 58V32C23 29.7909 21.2091 28 19 28H5C2.79086 28 1 26.2091 1 24V0"
              stroke="url(#paint0_linear_5935_147861)"
              stroke-width="2"
            />
            <defs>
              <linearGradient
                id="paint0_linear_5935_147861"
                x1="12"
                y1="18"
                x2="12"
                y2="58"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#7F7AFF" />
                <stop offset="1" stop-color="#E4E4E7" />
              </linearGradient>
            </defs>
          </svg>
        </div>
      </div>

      <div className="h-[234px] w-[334px] rounded-lg border">
        <div className="flex h-[32px] flex-row">
          <div className="flex h-[32px] w-[48px] flex-row items-center justify-center gap-1 border-b border-r">
            <div className="bg-pressed dark:bg-hovered h-2 w-2 rounded-full" />
            <div className="bg-pressed dark:bg-hovered h-2 w-2 rounded-full" />
            <div className="bg-pressed dark:bg-hovered h-2 w-2 rounded-full" />
          </div>
          <div className="flex h-[32px] w-[80px] flex-row items-center justify-center gap-2 border-r">
            <p className="text-soft text-[12px]">app.py</p>{" "}
            <X className="text-icon-soft" size={10} />
          </div>
          <div className="w-[210px] border-b" />
        </div>
        <div className="flex flex-col gap-2 px-3 py-4">
          <div className="flex flex-row items-start gap-2">
            <div className="bg-pressed dark:bg-hovered h-2 w-[66px] rounded-sm" />
            <div className="bg-pressed dark:bg-hovered h-2 w-[66px] rounded-sm" />
          </div>
          <div className="flex flex-row items-start gap-2">
            <div className="bg-pressed dark:bg-hovered h-2 w-[103px] rounded-sm" />
            <div className="bg-pressed dark:bg-hovered h-2 w-[199px] rounded-sm" />
          </div>
        </div>
      </div>
    </div>
  );
}

function StorageWindow() {
  return (
    <div className="origin] relative">
      <div className="dark:bg-default h-[220px] w-[330px] rounded-lg border-2 border-[#A297FF] bg-[#FFF]">
        <div className="flex h-[44px] w-full flex-row border-b px-3">
          <div className="flex h-[44px] w-[150px] items-center">
            <p className="text-left text-[14px] font-medium">Name</p>
          </div>
          <div className="flex h-[44px] w-[58px] items-center">
            <p className="text-soft text-left text-[14px]">Type</p>
          </div>
          <div className="flex h-[44px] w-[74px] items-center">
            <p className="text-soft text-left text-[14px]">App Name</p>
          </div>
        </div>
        <div className="flex h-[44px] w-full flex-row border-b px-3">
          <div className="flex h-[44px] w-[130px] items-center gap-1">
            <HardDrives size={16} />
            <p className="text-left text-[14px] font-medium">model-weight</p>
          </div>
          <div className="flex h-[44px] w-[78px] items-center">
            <p className="text-soft text-left text-[14px]">Persistent</p>
          </div>
          <div className="flex h-[44px] w-[74px] items-center">
            <p className="text-soft text-left text-[14px]">Llama70B</p>
          </div>
        </div>
        <div className="flex h-[44px] w-full flex-row border-b px-3">
          <div className="flex h-[44px] w-[130px] items-center gap-1">
            <HardDrives size={16} />
            <p className="text-left text-[14px] font-medium">config</p>
          </div>
          <div className="flex h-[44px] w-[78px] items-center">
            <p className="text-soft text-left text-[14px]">Shared</p>
          </div>
          <div className="flex h-[44px] w-[74px] items-center">
            <p className="text-soft text-left text-[14px]">fine-tune</p>
          </div>
        </div>
        <div className="flex h-[44px] w-full flex-row border-b px-3">
          <div className="flex h-[44px] w-[130px] items-center gap-1">
            <HardDrives size={16} />
            <p className="text-left text-[14px] font-medium">config</p>
          </div>
          <div className="flex h-[44px] w-[78px] items-center">
            <p className="text-soft text-left text-[14px]">Shared</p>
          </div>
          <div className="flex h-[44px] w-[74px] items-center">
            <p className="text-soft text-left text-[14px]">fine-tune</p>
          </div>
        </div>
        <div className="flex h-[44px] w-full flex-row border-b px-3">
          <div className="flex h-[44px] w-[130px] items-center gap-1">
            <HardDrives size={16} />
            <p className="text-left text-[14px] font-medium">config</p>
          </div>
          <div className="flex h-[44px] w-[78px] items-center">
            <p className="text-soft text-left text-[14px]">Shared</p>
          </div>
          <div className="flex h-[44px] w-[74px] items-center">
            <p className="text-soft text-left text-[14px]">fine-tune</p>
          </div>
        </div>
      </div>

      <div className="bg-subdued absolute left-[32px] top-[118px] h-[234px] w-[334px] rounded-lg border">
        <div className="flex h-[32px] flex-row">
          <div className="flex h-[32px] w-[48px] flex-row items-center justify-center gap-1 border-b border-r">
            <div className="bg-pressed dark:bg-hovered h-2 w-2 rounded-full" />
            <div className="bg-pressed dark:bg-hovered h-2 w-2 rounded-full" />
            <div className="bg-pressed dark:bg-hovered h-2 w-2 rounded-full" />
          </div>
          <div className="flex h-[32px] w-[80px] flex-row items-center justify-center gap-2 border-r">
            <p className="text-soft text-[12px]">app.py</p>{" "}
            <X className="text-icon-soft" size={10} />
          </div>
          <div className="w-[210px] border-b" />
        </div>
        <div className="flex flex-col gap-2 px-3 py-4">
          <div className="flex flex-row items-start gap-2">
            <div className="bg-pressed dark:bg-hovered h-2 w-[66px] rounded-sm" />
            <div className="bg-pressed dark:bg-hovered h-2 w-[66px] rounded-sm" />
          </div>
          <div className="flex flex-row items-start gap-2">
            <div className="bg-pressed dark:bg-hovered h-2 w-[103px] rounded-sm" />
            <div className="bg-pressed dark:bg-hovered h-2 w-[199px] rounded-sm" />
          </div>
        </div>
      </div>
    </div>
  );
}
