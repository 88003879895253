"use client";

import {
  ArrowRightLg,
  ChevronDown,
  Cpu,
  Env,
  HardDrive,
  HardDrives,
  TerminalWindow,
} from "@jengaicons/react";
import { Button } from "@primitives/button";
import { Row } from "@primitives/layout";
import { Link } from "@primitives/link";
import { Text } from "@primitives/text";

import { Section, SectionBody } from "../sections";
import { OpenSourceBadges } from "./open-source-badges";

export function Machines() {
  return (
    <Section className="border-b">
      <SectionBody className="relative flex w-full flex-col justify-center overflow-hidden lg:h-[523px] lg:flex-row">
        <div className="flex h-full shrink-0 flex-col items-center justify-center gap-4 border-b p-4 py-8 pr-0 md:p-16 lg:w-[504px] lg:border-b-0 lg:border-r">
          <Row className="text-soft w-full items-center justify-start gap-2 text-left">
            <HardDrives size={20} />
            <Text color="soft" weight="semibold" variant="headingMd">
              Machines
            </Text>
          </Row>

          <Text variant="heading3Xl" weight="bold" className="w-full text-left">
            Instant development environment
          </Text>
          <Text variant="bodyLg" color={"strong"}>
            Outpost machines offers a ready-to-use cloud development environment
            for machine learning and software development.
          </Text>

          <Link
            className="text-bodyLg w-full text-left font-medium"
            href="/docs/machines/get-started/overview"
            color="primary"
            suffix={<ArrowRightLg />}
          >
            Explore Machines
          </Link>
        </div>

        <div className="relative w-full flex-1 flex-col items-center justify-center gap-4 p-4 py-8 md:p-16">
          <div className="flex h-[366px] max-w-[438px] shrink-0 flex-col gap-4 rounded-lg border p-5 lg:w-[400px] xl:w-[438px]">
            <div className="bg-default flex h-[74px] w-full flex-row items-center justify-between rounded-lg border p-4">
              <div className="bg-subdued rounded p-2">
                <Env className="h-5 w-5" />
              </div>
              <div className="flex w-full flex-col items-center overflow-hidden pl-4">
                <Text
                  className="w-full truncate text-left"
                  variant={"headingMd"}
                  weight={"semibold"}
                >
                  outpost.run/username/repository
                </Text>
                <Text
                  className="w-full truncate text-left"
                  variant={"bodySm"}
                  color={"soft"}
                >
                  Context URL
                </Text>
              </div>
              <div className="rounded p-2">
                <ChevronDown className="h-5 w-5" />
              </div>
            </div>
            <div className="bg-default flex h-[74px] w-full flex-row items-center justify-between rounded-lg border p-4">
              <div className="bg-subdued rounded p-2">
                <TerminalWindow className="h-5 w-5" />
              </div>
              <div className="flex w-full flex-col items-center justify-start overflow-hidden pl-4">
                <Text
                  className="w-full truncate text-left"
                  variant={"headingMd"}
                  weight={"semibold"}
                >
                  Visual Studio Code
                </Text>
                <Text
                  className="w-full truncate text-left"
                  variant={"bodySm"}
                  color={"soft"}
                >
                  Editor: Desktop
                </Text>
              </div>
              <div className="rounded p-2">
                <ChevronDown className="h-5 w-5" />
              </div>
            </div>
            <div className="bg-default flex h-[74px] w-full flex-row items-center justify-between rounded-lg border p-4">
              <div className="bg-subdued rounded p-2">
                <Cpu className="h-5 w-5" />
              </div>
              <div className="flex w-full flex-col items-center overflow-hidden pl-4">
                <Text
                  className="w-full truncate text-left"
                  variant={"headingMd"}
                  weight={"semibold"}
                >
                  30-cores
                </Text>
                <Text
                  className="w-full truncate text-left"
                  variant={"bodySm"}
                  color={"soft"}
                >
                  54GB RAM - 100GB storage
                </Text>
              </div>
              <div className="rounded p-2">
                <ChevronDown className="h-5 w-5" />
              </div>
            </div>

            <div className="bg-primary flex h-[44px] w-full items-center justify-center rounded-lg border">
              <Text className="text-[#fff]">Create machine</Text>
            </div>
          </div>

          <div className="bg-default shadow-0.5 absolute right-[2%] top-[220px] flex h-[186px] w-[188px] shrink-0 flex-col justify-between rounded-lg border p-4 sm:right-[10%] md:left-[300px] md:right-auto md:top-[300px] md:h-[164px] md:w-[284px]">
            <Text color={"soft"} className="truncate">
              seed exec dev task.yaml <br /> [+] Running{" "}
              <span className="text-primary">14/21</span>
            </Text>
            <Text color={"soft"} className="truncate">
              🍊 This task ran as a machine prebuilt. <br />
              ⏱️ Well done on saving 12 mins
            </Text>
            <Text color={"soft"} className="truncate">
              <span className="text-primary">Outpost</span>{" "}
              <span className="text-success">/dev</span> $
            </Text>
          </div>
        </div>
      </SectionBody>
    </Section>
  );
}
